import React from 'react'
import Img from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Fade from 'react-reveal/Fade'

const Banner = ({ image, title, hero, linkText, link }) => {
  const HeadingTag = `h${hero ? '1' : '3'}`
  return (
    <div
      className={`w-full h-68 lg:h-175 flex items-center justify-center relative z-0${hero &&
        ' mb-15 lg:mb-30'}`}
    >
      {image.localFile && (
        <Fade ssrReveal delay={225} duration={2250}>
          <div className="absolute inset-x-0 w-full h-full">
            <Img
              className="h-full"
              fluid={image.localFile.childImageSharp.fluid}
              alt={image.alt || title}
            />
          </div>
        </Fade>
      )}
      {title && (
        <Fade ssrReveal delay={450} duration={2250} bottom distance={'25px'}>
          <div className="z-10 relative text-center">
            <HeadingTag
              className="whitespace-pre-line text-xl lg:text-2xl font-condensed uppercase tracking-wider"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            {link && (
              <div className="mt-10">
                <AniLink className="button" fade to={link.url}>
                  {linkText ? linkText : ''}
                </AniLink>
              </div>
            )}
          </div>
        </Fade>
      )}
    </div>
  )
}

export default Banner
