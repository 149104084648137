import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import Banner from '../components/Banner'
import ContactForm from '../components/Form/ContactForm'
import FooterSocialLinks from '../components/Footer/FooterSocialLinks'

const ContactPage = ({ location, data }) => {
  const pageData = data.prismicContact.data
  const global = data.prismicGlobal.data
  return (
    <Layout
      seoTitle={pageData.meta_title || pageData.title}
      seoDescription={pageData.meta_description}
      location={location}
    >
      <Banner title={pageData.title} image={pageData.hero_image} hero />

      <div className="px-ogs lg:pb-30">
        <div className="wrapper">
          <Fade ssrReveal delay={250} duration={2250}>
            <div className="lg:flex lg:-ml-gs">
              <div className="lg:w-1/2 lg:pl-gs">
                <div className="mb-12">
                  <h2 className="uppercase text-lg tracking-widest font-bold mb-5">
                    Contact Details
                  </h2>
                  {global.address.html && (
                    <div
                      className="mb-3"
                      dangerouslySetInnerHTML={{ __html: global.address.html }}
                    />
                  )}
                  <div className="mb-3">
                    {global.phone_numbers.length > 0 && (
                      <ul>
                        {global.phone_numbers
                          .filter(item => item.phone_number !== null)
                          .map((item, index) => (
                            <li key={index}>
                              <a
                                href={
                                  `tel:` + item.phone_number.replace(/\s/g, '')
                                }
                              >
                                {item.phone_number}
                              </a>
                            </li>
                          ))}
                      </ul>
                    )}
                    {global.email_addresses.length > 0 && (
                      <ul className="lg:mb-5">
                        {global.email_addresses
                          .filter(item => item.email_address !== null)
                          .map((item, index) => (
                            <li key={index}>
                              <a href={`mailto:` + item.email_address}>
                                {item.email_address}
                              </a>
                            </li>
                          ))}
                      </ul>
                    )}
                    <div className="hidden lg:block">
                      <FooterSocialLinks global={global} />
                    </div>
                  </div>
                </div>
                {global.opening_hours && (
                  <div className="mb-12">
                    <h3 className="uppercase text-lg tracking-widest font-bold mb-5">
                      Opening Hours
                    </h3>
                    <div className="mb-3">{global.opening_hours}</div>
                  </div>
                )}
              </div>
              <div className="lg:w-1/2 lg:pl-gs">
                <h2 className="uppercase text-lg tracking-widest font-bold mb-5">
                  Contact Form
                </h2>
                <ContactForm />
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </Layout>
  )
}

ContactPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ContactPage

export const query = graphql`
  query {
    prismicContact {
      data {
        meta_title
        meta_description
        hero_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        title
      }
    }
    prismicGlobal {
      data {
        email_addresses {
          email_address
        }
        facebook_url
        footer_menu {
          link {
            url
          }
          title
        }
        phone_numbers {
          phone_number
        }
        opening_hours
        instagram_url
        address {
          html
        }
      }
    }
  }
`
