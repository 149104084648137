import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import qs from 'qs'
import * as Yup from 'yup'

const FORM_NAME = 'maincontact'

const ContactForm = () => {
  const [failed, setFailed] = useState(false)
  const [completed, setCompleted] = useState(false)

  const initialValues = {
    name: '',
    email: '',
    subject: '',
    message: '',
    botField: null,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required()
      .label('Name'),
    email: Yup.string()
      .email()
      .required()
      .label('Email'),
    subject: Yup.string()
      .required()
      .label('Subject'),
    message: Yup.string()
      .required()
      .label('Message'),
  })

  // We use this as Formik's render component. Formik passes us all of its form API props
  const form = ({ isSubmitting, errors, touched }) => (
    <Form
      noValidate
      name={FORM_NAME}
      data-netlify="true"
      data-netlify-honeypot="botField"
    >
      <input type="hidden" name="form-name" value={FORM_NAME} />
      <div className="hidden">
        <input name="botField" />
      </div>
      <div className="field-wrapper">
        {/* <label htmlFor="name">
          Name<sup aria-hidden="true">*</sup>
        </label> */}
        <Field
          id="name"
          type="text"
          name="name"
          placeholder="Name"
          autoComplete="name"
          aria-required="true"
          aria-invalid={errors.name && touched.name}
          aria-describedby={errors.name && touched.name ? 'name-error' : null}
        />
        <p className="error-message" id="name-error">
          <ErrorMessage name="name" />
        </p>
      </div>
      <div className="field-wrapper">
        {/* <label htmlFor="email">
          Email<sup aria-hidden="true">*</sup>
        </label> */}
        <Field
          id="email"
          type="email"
          name="email"
          placeholder="Email"
          autoComplete="email"
          aria-required="true"
          aria-invalid={errors.email && touched.email}
          aria-describedby={
            errors.email && touched.email ? 'email-error' : null
          }
        />
        <p className="error-message" id="email-error">
          <ErrorMessage name="email" />
        </p>
      </div>
      <div className="field-wrapper">
        {/* <label htmlFor="subject">
          Subject<sup aria-hidden="true">*</sup>
        </label> */}
        <Field
          id="subject"
          type="text"
          name="subject"
          placeholder="Subject"
          aria-required="true"
          aria-invalid={errors.subject && touched.subject}
          aria-describedby={
            errors.subject && touched.subject ? 'subject-error' : null
          }
        />
        <p className="error-message" id="subject-error">
          <ErrorMessage name="subject" />
        </p>
      </div>
      <div className="field-wrapper">
        {/* <label htmlFor="message">
          Message<sup aria-hidden="true">*</sup>
        </label> */}
        <Field
          id="message"
          component="textarea"
          className="h-32"
          name="message"
          placeholder="Leave a message"
          aria-required="true"
          aria-invalid={errors.message && touched.message}
          aria-describedby={
            errors.message && touched.message ? 'message-error' : null
          }
        />
        <p className="error-message" id="message-error">
          <ErrorMessage name="message" />
        </p>
      </div>
      <div className="pt-7">
        <button className="button" type="submit" disabled={isSubmitting}>
          Submit
        </button>
      </div>
    </Form>
  )

  const handleSubmit = async (values, actions) => {
    // Netlify expects form submissions to be posted to the base URL
    // It also requires submissions to be URL encoded
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: qs.stringify({ 'form-name': FORM_NAME, ...values }),
    })
      .then(res => {
        if (!res.ok) throw new Error()
        setCompleted(true)
      })
      .catch(() => {
        setFailed(true)
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  if (failed) {
    return (
      <p>
        Sorry, we're having some trouble processing contact forms at the moment.
        Please try again later.
      </p>
    )
  }

  if (completed) {
    return <p>Thank you for contacting us. We will be in touch soon.</p>
  }

  return (
    <Formik
      component={form}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    ></Formik>
  )
}

export default ContactForm
